<template>
  <div></div>
<!--  <cm-table-data :pheaders="headers" :pconfig="config" :pcolumns="columns"/>-->
</template>

<script>

export default {
  name: 'index',
  data() {
    return {
      headers: [
        { value: 'actualAddress', sortable: false, text: 'Факт.адрес' },
        { value: 'bin', sortable: false, text: 'БИН' },
        { value: 'contactPerson', sortable: false, text: 'Контактное лицо' },
        { value: 'email', sortable: false, text: 'Email' },
        { value: 'legalAddress', sortable: false, text: 'Юр.адрес' },
        { value: 'name', sortable: false, text: 'Наименование' },
        { value: 'telephone', sortable: false, text: 'Телефон' },
        { value: 'type', sortable: false, text: 'Тип' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      config: {
        title: {
          main: 'Звонки',
          create: 'Создать',
          edit: 'Редактировать',
          delete: 'Удалить',
        },
        endpoints: {
          get: {
            method: 'get',
            url: '/api/v1/crm/suppliers',
            content: true,
          },
        },
      },
      columns: [
        { value: 'actualAddress', text: 'Факт.адрес' },
        { value: 'bin', text: 'БИН' },
        { value: 'contactPerson', text: 'Контактное лицо' },
        { value: 'email', text: 'Email' },
        { value: 'legalAddress', text: 'Юр.адрес' },
        { value: 'name', text: 'Наименование' },
        { value: 'telephone', text: 'Телефон' },
      ],
    };
  },
};
</script>

<style scoped>

</style>
